import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

import villageRouter from './modules/village'
import recruitment from './modules/recruitment'
import partymember from './modules/partymember'
import wangge from './modules/wangge'
import pension from './modules/pension'
import guanghe from './modules/guanghe'
import fangyi from './modules/fangyi'
import huayang from './modules/huayang'
import zhengxie from './modules/zhengxie'
import oldman from './modules/oldman'
import anLuoYun from './modules/anLuoYun'
import Lycal from './modules/Lycal'
import ylOrder from './modules/ylOrder'
import fivestar from './modules/fivestar'

/* Layout */
import Layout from '@/layout'

const router = new Router({
    routes: [{
        path: '/',
        component: Layout,
        children: [{
            path: '',
            name: 'Home',
            meta: {
                title: "掌上服务"
            },
            component: () =>
                import('@/views/home/index.vue')
        },]
    },
    {
        path: '/test',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "测试list"
            },
            component: () =>
                import('@/views/list.vue')
        },]
    },
    // 网上办事
    {
        path: '/work',
        component: Layout,
        children: [{
            name: 'WorkGroup',
            path: '',
            component: () =>
                import('@/views/work/group.vue')
        },
        {
            name: 'Work',
            path: ':nwkId',
            component: () =>
                import('@/views/work/index.vue')
        },
        {
            name: 'WorkApply',
            path: 'apply/:NWMId/:WorkKind',
            component: () =>
                import("@/views/work/apply/index.vue")
        }
        ]
    },
    // 社区活动
    {
        path: '/activity',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "社区活动"
            },
            component: () =>
                import('@/views/activity/index.vue')
        },
        {
            path: 'detail/:Id',
            meta: {
                title: "社区活动"
            },
            component: () =>
                import('@/views/activity/detail.vue')
        },
        ]
    },
    // 志愿者活动
    {
        path: '/volunteerAct',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "志愿者活动"
            },
            component: () =>
                import('@/views/volunteerAct/index.vue')
        },
        {
            path: 'detail/:Id',
            meta: {
                title: "志愿者活动"
            },
            component: () =>
                import('@/views/volunteerAct/detail.vue')
        },
        ]
    },
    // 我的活动记录 
    {
        path: '/activityLog',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "我的活动记录"
            },
            component: () =>
                import('@/views/activityLog/index.vue')
        },
        {
            path: 'detail/:Id/:paId',
            meta: {
                title: "我的活动记录"
            },
            component: () =>
                import('@/views/activityLog/detail.vue')
        },
        {
            path: '/detail/recActLogs/:Id',
            meta: {
                title: "心得体会"
            },
            component: () =>
                import('@/views/activityLog/recActLogs.vue')
        },
        ]
    },
    // 资讯中心
    {
        path: '/article',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "资讯中心"
            },
            component: () =>
                import('@/views/article/index.vue')
        },
        {
            path: 'detail/:Id',
            meta: {
                title: "资讯中心"
            },
            component: () =>
                import('@/views/article/detail.vue')
        },
        ]
    },
    // 社区风采
    {
        path: '/scenery',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "桥盟风采"
            },
            component: () =>
                import('@/views/scenery/index.vue')
        },
        {
            path: 'detail/:Id',
            meta: {
                title: "桥盟风采"
            },
            component: () =>
                import('@/views/scenery/detail.vue')
        },
        ]
    },
    // 便民商店
    {
        path: '/shop',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "惠民活动"
            },
            component: () =>
                import('@/views/shop/index.vue')
        }, {
            path: '/shop/detail',
            meta: {
                title: "活动详情"
            },
            component: () =>
                import('@/views/shop/detail.vue')
        }, {
            path: '/shop/type',
            meta: {
                title: "活动分类"
            },
            component: () =>
                import('@/views/shop/type.vue')
        }, {
            path: '/shop/PromotionType',
            meta: {
                title: "活动分类"
            },
            component: () =>
                import('@/views/shop/PromotionType.vue')
        }, {
            path: '/shop/list',
            meta: {
                title: "商城列表"
            },
            component: () =>
                import('@/views/shop/list.vue')
        }, {
            path: '/shop/orderDetail',
            meta: {
                title: "订单详情"
            },
            component: () =>
                import('@/views/shop/orderDetail.vue')
        }, {
            path: '/personal',
            meta: {
                title: "个人中心"
            },
            component: () =>
                import('@/views/shop/personal.vue')
        },
        {
            path: '/shop/PromotionXq/:Id',
            meta: {
                title: "活动详情"
            },
            component: () =>
                import('@/views/shop/PromotionXq.vue')
        }, {
            path: '/shop/PromotionList',
            meta: {
                title: "促销活动列表"
            },
            component: () =>
                import('@/views/shop/PromotionList.vue')
        }, {
            path: '/PromotionBm',
            meta: {
                title: "报名信息"
            },
            component: () =>
                import('@/views/shop/PromotionBm.vue')
        }, {
            path: '/shop/myPromotion',
            meta: {
                title: "我的报名"
            },
            component: () =>
                import('@/views/shop/myPromotion.vue')
        },]
    },
    // 通知公告
    {
        path: '/articleList',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "通知公告"
            },
            component: () =>
                import('@/views/article/articleList.vue')
        }]
    },
    // 个人中心
    {
        path: '/center',
        component: Layout,
        children: [{
            path: '',
            component: () =>
                import('@/views/center/index.vue')
        }]
    },
    // 通知公告
    {
        path: '/notice',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "通知公告"
            },
            component: () =>
                import('@/views/notice/index.vue')
        },
        {
            path: '/notice/detail/:Id',
            meta: {
                title: "通知公告"
            },
            component: () =>
                import('@/views/notice/detail.vue')
        }
        ]
    },
    // 会员注册
    {
        path: '/member',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "会员注册"
            },
            component: () =>
                import('@/views/member/index.vue')
        }]
    },
    // 走访巡街
    {
        path: '/watchman',
        component: Layout,
        children: [{
            path: '',
            meta: {
                title: "走访巡街"
            },
            component: () =>
                import('@/views/watchman/index.vue')
        },
        {
            path: '/watchman/trajectory/:Id',
            name: 'trajectory',
            meta: {
                title: "走访巡街"
            },
            component: () =>
                import('@/views/watchman/trajectory.vue')
        },
        {
            path: '/trajectoryMap',
            meta: {
                title: "走访巡街"
            },
            component: () =>
                import('@/views/watchman/trajectoryMap.vue')
        },
        ]
    },
    // 积分商城
    {
        path: '/integral',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: "积分商城"
                },
                component: () =>
                    import('@/views/integral/index.vue')
            }, {
                path: '/integral/detail/:Id',
                meta: {
                    title: "商品详情"
                },
                component: () =>
                    import('@/views/integral/detail.vue')
            }]
    },
        villageRouter, recruitment, partymember, wangge, pension, guanghe, fangyi, huayang, zhengxie, oldman, anLuoYun, Lycal, ylOrder, fivestar
    ]
});

// 动画效果
router.beforeEach((to, from, next) => {
    console.log(to.path)
    console.log(from.path)

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.path == from.path || ((to.path == '/' || to.path == '/serve' || to.path == '/center') && (from.path == '/' || from.path == '/serve' || from.path == '/login' || from.path == '/village'))) {
        store.commit('UPDATE_DIRECTION', "none")
    } else if (to.path.indexOf(from.path) != -1 || (from.name && to.name.indexOf(from.name) != -1)) {
        store.commit('UPDATE_DIRECTION', "forward")
    } else {
        store.commit('UPDATE_DIRECTION', "reverse")
    }
    next();
})

export default router