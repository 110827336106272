import Layout from '@/layout'

const villageRouter = {
  path: '/fivestar',
  component: Layout,
  children: [{
    path: '/',
    name: 'fivestar',
    meta: {
      title: "五星党建"
    },
    component: () =>
      import('@/views/fivestar/index.vue')
  }, {
    path: '/fivestar/zhibu',
    meta: {
      title: "支部过硬星"
    },
    component: () =>
      import('@/views/fivestar/zhibu.vue')
  }, {
    path: '/fivestar/gongjian',
    meta: {
      title: "共建共享星"
    },
    component: () =>
      import('@/views/fivestar/gongjian.vue')
  }, {
    path: '/fivestar/fazhi',
    meta: {
      title: "平安法治星"
    },
    component: () =>
      import('@/views/fivestar/fazhi.vue')
  }, {
    path: '/fivestar/xingfu',
    meta: {
      title: "幸福和谐星"
    },
    component: () =>
      import('@/views/fivestar/xingfu.vue')
  }, {
    path: '/fivestar/xingye',
    meta: {
      title: "宜业兴业星"
    },
    component: () =>
      import('@/views/fivestar/xingye.vue')
  }, {
    path: '/fivestar/wuXingXq/:Id',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/fivestar/wuXingXq.vue')
  }, {
    path: '/fivestar/detail/:Id',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/article/detail.vue')
  }, 
  {
    path: '/fivestar/integral',
    meta: {
        title: "积分商城"
    },
    component: () =>
        import('@/views/integral/index.vue')
}, {
    path: '/fivestar/integral/detail/:Id',
    meta: {
        title: "商品详情"
    },
    component: () =>
        import('@/views/integral/detail.vue')
}
  // {
  //   path: '/fivestar/jifenShop/index',
  //   meta: {
  //     title: "积分商城"
  //   },
  //   component: () =>
  //     import('@/views/fivestar/jifenShop/index.vue')
  // }, {
  //   path: '/jifenShop/detail',
  //   meta: {
  //     title: "商铺详情"
  //   },
  //   component: () =>
  //     import('@/views/fivestar/jifenShop/detail.vue')
  // }, {
  //   path: '/jifenShop/list',
  //   meta: {
  //     title: "商城列表"
  //   },
  //   component: () =>
  //     import('@/views/fivestar/jifenShop/list.vue')
  // }, {
  //   path: '/jifenShop/type',
  //   meta: {
  //     title: "商铺分类"
  //   },
  //   component: () =>
  //     import('@/views/fivestar/jifenShop/type.vue')
  // },
]
}

export default villageRouter